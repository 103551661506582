<template>
	<div>
		<h4 class="h4_title">出金信息</h4>
		<van-cell-group>
			<van-cell title="出金金额" :value="total" />
			<van-cell title="提交日期" :value="date" />
			<van-cell title="审核日期" :value="verify_date" v-if="verify_date"/>
			<van-cell title="转账日期" :value="transfer_date" v-if="transfer_date"/>
			<van-cell title="当前状态">
				<template #right-icon>
					<span style="color: grey;" v-if="status == 1">待处理</span>
					<span style="color: steelblue;" v-if="status == 2">已审核</span>
					<span style="color: red;" v-if="status == 3">已取消</span>
					<span style="color: green;" v-if="status == 4">已划款</span>
				</template>
			</van-cell>
			<van-cell title="处理结果" :value="reason" v-if="reason"/>
		</van-cell-group>
		
		<h4 class="h4_title">收款账户</h4>
		<van-cell-group>
			<van-cell title="账户类型" :value="bank_type" />
			<van-cell v-for="(item, key) in bank_info" :title="key" :value="item" :key="key" />
		</van-cell-group>
	</div>
</template>

<script>
	export default {
		name: 'withdraw_info',
		data() {
			return {
				formItem: {
					id: "",
					token: ""
				},
				
				total: "",
				total_cny: "",
				name: "",
				bank_type: "",
				bank_info: {},
				date: "",
				verify_date: "",
				transfer_date: "",
				status: "",
				reason: ""
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.formItem.id = this.$route.query.id
				
				this.loadData()
			}
		},
		methods: {
			loadData() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'cash_withdraw/info', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {
						this.total = res.data.total
						this.total_cny = res.data.total_cny
						this.name = res.data.name
						this.bank_type = res.data.bank_type
						this.bank_info = res.data.bank_info
						this.date = res.data.date
						this.verify_date = res.data.verify_date
						this.transfer_date = res.data.transfer_date
						this.status = res.data.status
						this.reason = res.data.reason
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			}
		}
	}
</script>